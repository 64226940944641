import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className='welcome-text'>Welcome to Cheeez.xyz</h1>
      <img src="https://eshansingh.s3.ap-south-1.amazonaws.com/cheeese.png" alt="Cheeez Logo" className="logo" />
    </div>
  );
}

export default App;
